const domReadyStates = ["complete", "interactive"];
const whenDomReady = () => new Promise((resolve) => {
  const { readyState } = window.document;
  if (domReadyStates.includes(readyState)) {
    resolve();
  } else {
    document.addEventListener("DOMContentLoaded", () => resolve());
  }
});
export {
  whenDomReady
};
