import { wrap, windowEndpoint, expose } from "comlink";
import { logger } from "@leon-hub/logging";
import { AbstractErrorCode, AbstractError, normalizeError } from "@leon-hub/errors";
import { delay, Deferred, promiseTimeout } from "@leon-hub/utils";
import once from "lodash/once";
import { getLocationOrigin } from "@leon-hub/service-locator-env";
import { whenDomReady } from "@leon-hub/dom-ready";
import { assert } from "@leon-hub/guards";
import createDebug from "debug";
const DEFAULT_TIMEOUT = 5e3;
const sandboxScriptErrorMessage = "script-error";
const isTelegramInAppBrowser = async () => {
  await whenDomReady();
  if (process.env.VUE_APP_PLATFORM_WEB) {
    return typeof window.TelegramWebviewProxy !== "undefined";
  }
  return false;
};
const getFrameId = (id) => `sandbox-${id}`;
class SandboxApiErrorCode extends AbstractErrorCode {
}
class SandboxApiError extends AbstractError {
  constructor(options) {
    super({
      ...options,
      code: new SandboxApiErrorCode("SANDBOX_API_ERROR_CODE")
    });
  }
}
function printLoadableScript(script) {
  return `<script defer onerror="postMessage('${sandboxScriptErrorMessage}')" crossorigin="anonymous" src="${script}"><\/script>`;
}
async function printScript({ script, retry = 2 }) {
  if (typeof script === "string") {
    return Promise.resolve(printLoadableScript(script));
  }
  if (!script.inline) {
    return Promise.resolve(printLoadableScript(script.src));
  }
  let counter = retry;
  try {
    do {
      const response = await fetch(script.src);
      if (response.ok) {
        const content = await response.text();
        return `<script onerror="postMessage('${sandboxScriptErrorMessage}', '*')">try{${content}}catch(e){postMessage('${sandboxScriptErrorMessage}', '*')}<\/script>`;
      }
      await delay(100);
      counter -= 1;
    } while (counter > 0);
  } catch (cause) {
    throw new SandboxApiError({
      cause
    });
  }
  throw new SandboxApiError({
    message: "Unable to fetch sandbox resource"
  });
}
const fetchSandboxHtml = /* @__PURE__ */ once(async () => (await fetch(`${process.env.BASE_URL}sandbox.html`)).text());
const configureFrame = async (iframe, options) => {
  const id = getFrameId(options.id);
  iframe.setAttribute("id", id);
  iframe.setAttribute("title", `Sandboxed api iframe: ${options.id}`);
  iframe.setAttribute("width", "1");
  iframe.setAttribute("height", "1");
  iframe.setAttribute("style", "opacity: 0; position: absolute; left: -1px");
  if (options.strict) {
    iframe.setAttribute("sandbox", "allow-scripts");
  }
  iframe.style.opacity = "0";
  const origin = options.origin ?? getLocationOrigin();
  const [sandboxContent, ...scripts] = await Promise.all([
    fetchSandboxHtml(),
    ...options.scripts.map((script) => printScript({ script }))
  ]);
  const html = sandboxContent.replace(/(<head[^>]*?>)/, [
    "$1",
    `<base href="${origin}"/>`,
    options.strict ? `<meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'self' 'unsafe-eval' ${origin}; style-src 'self' ${origin}">` : "",
    options.sentry ? `<script>sentryOptions = ${JSON.stringify(options.sentry)}<\/script>` : ""
  ].join("")).replace("</body>", [
    ...scripts,
    "</body>"
  ].join(""));
  if ((process.env.VUE_APP_OS_IOS || process.env.VUE_APP_OS_MACOS) && !await isTelegramInAppBrowser()) {
    const blob = new Blob([
      html
    ], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    iframe.setAttribute("src", url);
    iframe.addEventListener("load", () => {
      URL.revokeObjectURL(url);
    });
  } else {
    iframe.srcdoc = html;
  }
  return iframe;
};
const getFrameWindow = (iframe) => {
  const { contentWindow } = iframe;
  assert(contentWindow);
  return contentWindow;
};
async function getListeningFrameWindow(iframe) {
  if (await isTelegramInAppBrowser()) {
    await delay(1e3);
  }
  let { contentWindow } = iframe;
  if (!contentWindow) {
    let resolved = false;
    let interval;
    contentWindow = await Promise.race([
      new Promise((resolve) => {
        interval = setInterval(() => {
          if (!resolved && iframe.contentWindow) {
            resolve(iframe.contentWindow);
          }
        }, 100);
      }),
      new Promise((resolve) => {
        iframe.addEventListener("load", () => {
          if (!resolved) {
            resolve(getFrameWindow(iframe));
          }
        });
      })
    ]).finally(() => {
      resolved = true;
      if (interval) clearInterval(interval);
    });
  }
  const { addEventListener, removeEventListener } = contentWindow;
  await new Promise((resolve) => {
    function onMessage({ data }) {
      if (data === "listening") {
        removeEventListener("message", onMessage);
        resolve();
      }
    }
    addEventListener("message", onMessage);
  });
  return contentWindow;
}
async function connectWindow(iframe, options) {
  const deferred = new Deferred();
  const contentWindow = await getListeningFrameWindow(iframe);
  const { addEventListener, removeEventListener } = contentWindow;
  addEventListener("message", function onMessage({ data }) {
    if (data === "connected") {
      removeEventListener("message", onMessage);
      deferred.resolve();
    }
  });
  contentWindow.postMessage("connect", "*");
  return deferred.promise;
}
const debug = /* @__PURE__ */ createDebug("@leon-hub/script-sandbox");
const getSandboxContext = (options) => {
  let isStopped = false;
  if (!options.scripts.length) throw new Error("No scripts to sandbox");
  const log = debug.extend(options.id);
  const frameID = getFrameId(options.id);
  if (document.getElementById(frameID)) {
    throw new Error(`Unexpected sandbox frame with id=${frameID}`);
  }
  const iframe = document.createElement("iframe");
  function stop() {
    var _a;
    if (!isStopped) {
      isStopped = true;
      log("stopping");
      (_a = iframe.parentNode) == null ? void 0 : _a.removeChild(iframe);
    }
  }
  try {
    iframe.addEventListener("beforeunload", () => {
      log("unloading");
      stop();
    });
    return {
      iframe,
      api: configureFrame(iframe, options).then(() => {
        log("installing dom..");
        iframe.addEventListener("message", ({ data }) => {
          if (data === sandboxScriptErrorMessage) {
            logger.error(`Sandbox "${options.id}" script error`);
            stop();
          }
        });
        document.body.appendChild(iframe);
      }).then(() => connectWindow(iframe, { id: options.id })).then(() => wrap(windowEndpoint(getFrameWindow(iframe)))).catch((err) => {
        stop();
        const normalizedErr = normalizeError(err);
        logger.error("Unable to finish sandbox initialization", normalizedErr);
        return Promise.reject(normalizedErr);
      }),
      stop
    };
  } catch (err) {
    stop();
    throw err;
  }
};
const sandboxApi = async (options) => {
  const context = getSandboxContext(options);
  return promiseTimeout({
    promise: context.api,
    timeout: options.timeout ?? DEFAULT_TIMEOUT,
    onTimeout: () => {
      {
        try {
          context.stop();
        } catch (err) {
          logger.warn(`Unable to tear down sandbox ${options.id} during timeout error`, normalizeError(err));
        }
      }
    }
  }).then((api) => ({ ...context, api }));
};
const connect = (self, parent, origin) => {
  expose(self, windowEndpoint(parent, self, origin));
};
export {
  connect,
  sandboxApi
};
