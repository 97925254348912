import { addBreadcrumb, browserProfilingIntegration, browserTracingIntegration, captureException, getCurrentScope, init, replayIntegration, setTags, setUser } from "@sentry/vue";
import { captureConsoleIntegration, contextLinesIntegration, extraErrorDataIntegration, httpClientIntegration, sessionTimingIntegration } from "@sentry/integrations";
import { captureConsoleIntegration as captureConsoleIntegration2, contextLinesIntegration as contextLinesIntegration2, extraErrorDataIntegration as extraErrorDataIntegration2, httpClientIntegration as httpClientIntegration2, sessionTimingIntegration as sessionTimingIntegration2 } from "@sentry/integrations";
import { IgnoredErrorType } from "@leon-hub/api-sdk";
import { isString } from "@leon-hub/guards";
import { isSentryLoggableError } from "@leon-hub/errors-whitelist";
import { init as init2, makeBrowserOfflineTransport, makeFetchTransport, setTag } from "@sentry/browser";
function isLoggableError(error, ignoredErrors) {
  return isSentryLoggableError(error) && !isIgnoredErrorMessage(error, ignoredErrors);
}
function isIgnoredErrorMessage(error, ignoredErrors) {
  if (!ignoredErrors) {
    return false;
  }
  const errorCombined = getErrorMessage(error);
  if (!errorCombined) {
    return false;
  }
  for (const ignoredError of ignoredErrors) {
    const ignoredErrorMessage = ignoredError.error;
    if (ignoredError.type === IgnoredErrorType.TEXT && errorCombined.includes(ignoredErrorMessage)) {
      return true;
    }
    if (ignoredError.type === IgnoredErrorType.REGEXP) {
      const regexp = new RegExp(ignoredErrorMessage);
      if (regexp.test(errorCombined)) {
        return true;
      }
    }
  }
  return false;
}
function getErrorMessage(error) {
  let errorMessage = "";
  let errorStack = "";
  if (error instanceof Error) {
    if (error.message) {
      errorMessage = error.message;
    }
    if (error.stack) {
      errorStack = error.stack;
    }
  } else if (isString(error)) {
    errorMessage = String(error);
  }
  if (!errorMessage && !errorStack) {
    return "";
  }
  return `${errorMessage} ${errorStack}`;
}
function initSentry(options) {
  init2({
    ...options,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    transportOptions: {
      maxQueueSize: 10
    }
  });
  setTag("frame", "sandbox");
}
function getSandboxIntegrations() {
  return [
    captureConsoleIntegration(),
    contextLinesIntegration(),
    extraErrorDataIntegration(),
    httpClientIntegration(),
    sessionTimingIntegration()
  ];
}
function addSandboxIntegrations(options) {
  return {
    ...options,
    ...options.parameters,
    integrations: [
      ...getSandboxIntegrations()
    ]
  };
}
export {
  addBreadcrumb,
  addSandboxIntegrations,
  browserProfilingIntegration,
  browserTracingIntegration,
  captureConsoleIntegration2 as captureConsoleIntegration,
  captureException,
  contextLinesIntegration2 as contextLinesIntegration,
  extraErrorDataIntegration2 as extraErrorDataIntegration,
  getCurrentScope,
  httpClientIntegration2 as httpClientIntegration,
  init,
  initSentry,
  isLoggableError,
  replayIntegration,
  sessionTimingIntegration2 as sessionTimingIntegration,
  setTags,
  setUser
};
