import { isObject, isNumber } from "@leon-hub/guards";
import { ApiServiceUnavailableError, GqlApiAccessDeniedError, GqlApiPreviousRequestHasNotExpired, GqlApiCustomerHistoryLimitExceededError, ApiIpBlockedError } from "@leon-hub/api";
import { AbstractError, LogLevel } from "@leon-hub/errors";
import { AppIgnoredError } from "@leon-hub/app-errors";
import { InvalidCodeExceptionCode } from "@leon-hub/api-sdk";
function isCommonWhitelistedError(error) {
  return !!(isObject(error) && (error instanceof AbstractError && (error.logLevel === LogLevel.DEBUG || error.silent || error instanceof ApiServiceUnavailableError || error instanceof GqlApiAccessDeniedError || error instanceof GqlApiPreviousRequestHasNotExpired || error instanceof GqlApiCustomerHistoryLimitExceededError || error instanceof ApiIpBlockedError || error instanceof AppIgnoredError) || error instanceof Error && (error.message.includes("This browser doesn't support the API's required to use the Firebase") || error.message.includes("[webpack-dev-server]") || !!(error.stack && error.stack.includes("googletagmanager"))) || error._isRouter && isNumber(error.type) && [
    2,
    16,
    8
    /* cancelled */
  ].includes(error.type) || error._isWhiteListed));
}
function isOpenSearchLoggableError(error) {
  if (isCommonWhitelistedError(error)) {
    return false;
  }
  return true;
}
function isSentryLoggableError(error) {
  if (isCommonWhitelistedError(error)) {
    return false;
  }
  if (error instanceof AbstractError && error.code.equals(InvalidCodeExceptionCode.INVALID_CODE.toString())) {
    return false;
  }
  return true;
}
export {
  isOpenSearchLoggableError,
  isSentryLoggableError
};
